


















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import CommentShowType from "./index"
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";
import {Toast} from "vant";
import {comment} from "@/views/Friend/FriendSuccess/Server";

@Component({name: "CommentShow"})
export default class CommentShow extends Vue implements CommentShowType{
    message = "";
    show = false

    mounted(){
        this.show = this.getCommentShow
    }

    handleUpComment(): void {
        if ( !(this.message && this.message.length) ){
            Toast({ message:"请输入评论" })
            return;
        }
        comment(this.message).then(res=>{
            this.$emit("reftch")
            this.handleClose()
        })
    }

    handleClose(): void {
        FriendSuccessStore.setCommentShow( false )
    }

    @Prop(String)
    placeValue!:string
    get getPlaceValue(){
        return this.placeValue || '请输入评论内容'
    }

    @Watch("show")
    changeShow(newVal:boolean){
        FriendSuccessStore.setCommentShow( newVal )
    }

    get getCommentShow(){
        return FriendSuccessStore.getCommentShow
    }
    @Watch("getCommentShow")
    changeCommentShow(newVal:boolean){
        this.show = newVal
    }
}
